import React from "react";
import SEO from "@components/common/seo";
import Layout from "@components/layout";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import bonus from "@components/images/baji999/baji999-bonus.webp";
import soccer from "@components/images/baji999/baji999-soccer.webp";
import basketball from "@components/images/baji999/baji999-basketball.webp";
import kabaddi from "@components/images/baji999/baji999-kabaddi.webp";
import cricket from "@components/images/baji999/baji999-cricket.webp";
import horseRacing from "@components/images/baji999/baji999-horse-racing.webp";
import virtualSports from "@components/images/baji999/baji999-virtual-sports.webp";
import banner2 from "@components/images/baji999/baji999-1536x431.webp";

import registrationBonus from "@components/images/baji999/baji999-registration-bonus.webp";
import firstDeposit from "@components/images/baji999/baji999-bonus-for-first-deposit.webp";
import activityBonus from "@components/images/baji999/baji999-gaming-activity-bonus.webp";
import refferalBonus from "@components/images/baji999/baji999-refferal-bonus.webp";
import topUp from "@components/images/baji999/baji999-top-up.webp";
import reloadBonus from "@components/images/baji999/baji999-reload-bonus.webp";
import cashback from "@components/images/baji999/baji999-cashback.webp";

import leaderboard from "@components/images/baji999/baji999-seasonal-leaderboard.webp";
import VIPPoints from "@components/images/baji999/baji999-VIP-points.webp";
import VIPManager from "@components/images/baji999/baji999-VIP-manager.webp";
import VIPRewards from "@components/images/baji999/baji999-VIP-exclusive-rewards.webp";
import VIPPayment from "@components/images/baji999/baji999-VIP-payment-channel.webp";

import register from "@components/images/baji999/baji999-register.webp";
import slots from "@components/images/baji999/baji999-slots.webp";
import tableGames from "@components/images/baji999/baji999-table-games.webp";
import jackpots from "@components/images/baji999/baji999-jackpots.webp";
import fishing from "@components/images/baji999/baji999-fishing.webp";
import liveDealer from "@components/images/baji999/baji999-live-dealer-casino.webp";

import app from "@components/images/baji999/baji999-application.webp";

import withLocation from "@helpers/hoc/withLocation";

const Baji999 = ({ location }: any) => {
  return (
    <Layout location={location.pathname}>
      <div className="bg-[white]">
        <SEO
          title="Baji999 Live - Cricket Betting & Live Casino & Bonuses"
          description="Baji999 is the perfect place to gamble online. Cricket betting, live casino and more. Claim your 100% welcome bonus and enjoy the game!"
        />

        <div className="bg-[url('@components/images/baji999/bg1.webp')] bg-no-repeat bg-cover">
          <div className="content-container p-[15px]">
            <h2 className="text-2xl text-center text-white">
              Baji999 Live - कैसीनो और खेल सट्टेबाजी
            </h2>
            <div className="grid items-center md:grid-cols-2">
              <div className="flex flex-col items-center">
                <img src={bonus} alt="bonus" className="w-[350px] mb-0" />
                <h3 className="my-2 text-lg italic font-medium text-white md:text-2xl">
                  Welcome Offer - 100% First Deposit Bonus
                </h3>
                <button className="flex items-center justify-center bg-gradient-to-r from-[#f69501] to-[#fbc004] rounded-[5px] text-lg h-[45px] px-[24px] text-white my-3">
                  Pick Up Bonus
                </button>
              </div>
              <p className="text-sm text-center text-white md:text-lg">
                <span className="font-bold">Baji999</span> बांग्लादेश में स्थित
                एक गेमिंग प्लेटफॉर्म है, जो न केवल उस देश में बल्कि लगभग पूरे
                दक्षिण पूर्व एशिया और भारत में गेमर्स के बीच तेजी से लोकप्रिय हो
                रहा है। यह उन खिलाड़ियों के लिए खेल और सुविधाओं की एक रोमांचक
                श्रृंखला प्रदान करता है जो अपने कौशल का परीक्षण करना चाहते हैं
                और खुद को चुनौती देना चाहते हैं। शतरंज और महाजोंग जैसे क्लासिक
                बोर्ड गेम से लेकर आभासी दुनिया में रोमांचक कारनामों तक, Baji999
                में सभी के लिए कुछ न कुछ है। ग्राफिक्स आश्चर्यजनक रूप से
                यथार्थवादी हैं, ध्वनि प्रभाव यथार्थवादी हैं और गेमप्ले सहज और
                आनंददायक है।
              </p>
            </div>
          </div>
        </div>

        <div className="bg-[#353535]">
          <div className="content-container md:px-[15px]">
            <Accordion className="!bg-[#353535] md:w-[70%] mx-auto !rounded-none !shadow-none">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="text-[#fff]" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="!border-b-[1px] !border-[#fff] !border-solid"
              >
                <h2 className="text-[#fff]">संतुष्ट</h2>
              </AccordionSummary>
              <AccordionDetails>
                <ul className="list-item text-[#fff]">
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      Baji999 समीक्षा
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      बोनस और प्रचार
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      वीआईपी-क्लब
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      पंजीकरण की प्रक्रिया
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      फायदे और नुकसान
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      ऑनलाइन कसीनो
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      खेल में सट्टेबाजी
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      आवेदन
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      जमा करने के तरीके
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      सुरक्षा
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      तकनीकी ग्राहक सेवा
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      अक्सर पूछे जाने वाले प्रश्न
                    </a>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>

            <div className="bg-[#484848] mt-4 py-4">
              <div className="grid grid-cols-3">
                <div>
                  <img
                    className="mb-0 w-[70%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={soccer}
                    alt="फ़ुटबॉल"
                  />
                  <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                    फ़ुटबॉल
                  </p>
                </div>
                <div>
                  <img
                    className="mb-0 w-[70%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={basketball}
                    alt="क्रिकेट"
                  />
                  <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                    क्रिकेट
                  </p>
                </div>
                <div>
                  <img
                    className="mb-0 w-[70%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={kabaddi}
                    alt="बास्केटबाल"
                  />
                  <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                    बास्केटबाल
                  </p>
                </div>
                <div>
                  <img
                    className="mb-0 w-[70%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={cricket}
                    alt="घुड़दौड़"
                  />
                  <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                    घुड़दौड़
                  </p>
                </div>
                <div>
                  <img
                    className="mb-0 w-[70%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={horseRacing}
                    alt="कबड्डी"
                  />
                  <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                    कबड्डी
                  </p>
                </div>
                <div>
                  <img
                    className="mb-0 w-[70%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={virtualSports}
                    alt="आभासी खेल"
                  />
                  <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                    आभासी खेल
                  </p>
                </div>
              </div>

              <div className="border-y-[5px] border-[#353535] mt-5 py-5 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  Baji 999 की समीक्षा
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  एक गेमिंग प्लेटफॉर्म जो अपने ग्राहकों के लिए विभिन्न प्रकार के
                  गेम पेश करता है। प्लेटफॉर्म में एडवेंचर और फाइटिंग गेम्स से
                  लेकर क्लासिक बोर्ड गेम्स, स्पोर्ट्स पर बेटिंग, साइबर स्पोर्ट्स
                  और बहुत कुछ हैं। इसमें मल्टीप्लेयर विकल्पों का एक बड़ा चयन भी
                  शामिल है, ताकि गेमर्स वास्तविक समय में एक दूसरे के खिलाफ
                  प्रतिस्पर्धा कर सकें।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  गेम के ग्राफ़िक्स यथार्थवादी और तरल हैं, जो गेमप्ले को बहुत
                  दिलचस्प बनाते हैं। सरल लेकिन प्रभावी नियंत्रणों के लिए
                  धन्यवाद, खिलाड़ी आसानी से विभिन्न स्तरों के माध्यम से
                  युद्धाभ्यास कर सकते हैं। इसके अलावा, पृष्ठभूमि संगीत भी सुखद
                  और सुखदायक है, जो आपके पसंदीदा गेम को खेलते समय वातावरण में
                  जोड़ता है।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  कुल मिलाकर, Baji999 एक बेहतरीन मंच है जो लोगों को अपने घर में
                  आराम से रोमांचक खेलों का आनंद लेने की अनुमति देता है। इसका
                  उपयोग करना आसान है, इसमें खेलों का व्यापक चयन है और यह एक
                  बेहतरीन मल्टीप्लेयर अनुभव प्रदान करता है। सभी गेमर्स के लिए
                  अत्यधिक अनुशंसित!
                </p>
                <img className="mb-0" src={banner2} alt="banner" />
              </div>

              <div className="border-b-[5px] border-[#353535] mt-5 pb-5 mx-3">
                <table className="w-[100%]">
                  <thead className="">
                    <tr>
                      <th className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        आधिकारिक नाम
                      </th>
                      <th className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        BAJI999
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        लाइसेंस
                      </td>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        कुराकाओ
                      </td>
                    </tr>
                    <tr>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        स्थापना का वर्ष
                      </td>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        2021
                      </td>
                    </tr>
                    <tr>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        खेलों की संख्या
                      </td>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        20+
                      </td>
                    </tr>
                    <tr>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        मोबाइल एप्लिकेशन
                      </td>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        एंड्रॉयड
                      </td>
                    </tr>
                    <tr>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        सहायता
                      </td>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        24/7
                      </td>
                    </tr>
                    <tr>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        ग्राहक सहेयता
                      </td>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        ईमेल, लाइव चैट और सोशल मीडिया
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="border-b-[5px] border-[#353535] py-5 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  बोनस & प्रचार
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  कैसीनो Baji.999 नए उपयोगकर्ताओं और नियमित ग्राहकों दोनों के
                  लिए नकद बोनस, फ्रीस्पिन और कॉम्प प्रदान करता है। यह गेमर्स को
                  जुआ प्रतिष्ठान के लिए अभ्यस्त होने और वस्तुतः बिना किसी कीमत
                  पर सक्रिय रूप से जुआ खेलने की अनुमति देता है। साइट कई प्रकार
                  के बोनस प्रदान करती है। क्लब के सदस्यों को नियमित आधार पर और
                  अल्पकालिक पदोन्नति के हिस्से के रूप में बोनस की पेशकश की जाती
                  है। Baji999 निम्नलिखित मुख्य बोनस प्रदान करता है:
                </p>
                <div className="flex flex-wrap">
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[30%] md:w-[25%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={registrationBonus}
                      alt="पंजीकरण कराना"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      पंजीकरण कराना
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[30%] md:w-[25%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={firstDeposit}
                      alt="प्रथम जमा के लिए"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      प्रथम जमा के लिए
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[30%] md:w-[25%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={activityBonus}
                      alt="गेमिंग गतिविधि के लिए"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      गेमिंग गतिविधि के लिए
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[30%] md:w-[25%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={refferalBonus}
                      alt="रेफरल बोनस"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      रेफरल बोनस
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[30%] md:w-[16%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={topUp}
                      alt="एक निश्चित राशि के साथ टॉप अप करें"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      एक निश्चित राशि के साथ टॉप अप करें
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[30%] md:w-[16%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={reloadBonus}
                      alt="10,000 INR तक रीलोड बोनस"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      10,000 INR तक रीलोड बोनस
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[20%] md:w-[16%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={cashback}
                      alt="नकदी वापस"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      नकदी वापस
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-[#000]">
              <div className="!py-5 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  वीआईपी-क्लब
                </h2>
                <div className="flex flex-wrap justify-center">
                  <img
                    className="mb-2 p-[10px] md:w-[33%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={leaderboard}
                    alt="leaderboard"
                  />
                  <img
                    className="mb-2 p-[10px] md:w-[33%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={VIPPoints}
                    alt="VIP points"
                  />
                  <img
                    className="mb-2 p-[10px] md:w-[33%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={VIPManager}
                    alt="VIP manager"
                  />
                  <img
                    className="mb-2 p-[10px] md:w-[33%] duration-[.3s] hover:scale-[.9]"
                    src={VIPRewards}
                    alt="VIP rewards"
                  />
                  <img
                    className="mb-2 p-[10px] md:w-[33%] duration-[.3s] hover:scale-[.9]"
                    src={VIPPayment}
                    alt="VIP payment"
                  />
                </div>
                <p className="mb-4 text-sm text-white md:text-lg">
                  Baji999 VIP क्लब उन गेमर्स के लिए परम गेमिंग अनुभव है जो अपने
                  गेमिंग को अगले स्तर पर ले जाना चाहते हैं। वीआईपी क्लब के एक
                  सदस्य के रूप में, आपके पास विशेष पुरस्कारों और विशेषाधिकारों
                  तक पहुंच होगी जो केवल सदस्यों के लिए उपलब्ध हैं। विशेष छूट,
                  विशेष टूर्नामेंट और प्रतियोगिताओं, और बहुत कुछ के साथ, गेमर्स
                  के इस विशिष्ट समूह का हिस्सा होना वास्तव में एक अविस्मरणीय
                  अनुभव होगा।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  वीआईपी क्लब के सदस्य के रूप में, आपको बाजी 999 के माध्यम से की
                  गई सभी खरीदारी पर विशेष छूट प्राप्त होगी। आपको खेल के समय या
                  क्रेडिट जैसे कभी-कभी मुफ्त उपहारों के अलावा किसी और से पहले
                  सभी आगामी कार्यक्रमों में प्राथमिकता प्राप्त होगी। इसके
                  अतिरिक्त, वीआईपी सदस्य विशेष टूर्नामेंट में भाग ले सकते हैं
                  जहां वे गेमिंग हार्डवेयर और उपहार कार्ड जैसे पुरस्कार जीत सकते
                  हैं।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  आप वीआईपी क्लब के साथ एक जीवंत और उत्साही गेमिंग समुदाय का भी
                  हिस्सा होंगे। आपके पास विशेष फ़ोरम, चैट, और बहुत कुछ होगा,
                  ताकि आप साथी गेमर्स से बात कर सकें और अपने पसंदीदा गेम में
                  उत्कृष्ट प्रदर्शन करने के तरीके के बारे में टिप्स और रणनीतियाँ
                  साझा कर सकें। और अगर यह पर्याप्त नहीं था, तो वीआईपी सदस्यों को
                  क्लब का हिस्सा बनने के लिए हर महीने विशेष इन-गेम पुरस्कार
                  प्राप्त होंगे।
                </p>
              </div>
            </div>

            <div className="bg-[#484848] py-4">
              <div className="border-y-[5px] border-[#353535] py-4 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  Baji999 पंजीकरण प्रक्रिया
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  कोई भी Baji999 ऑनलाइन गेमिंग प्लेटफॉर्म पर रजिस्टर कर सकता है।
                  मुख्य बात यह है कि वह कानूनी उम्र का होना चाहिए। अवयस्क पाए
                  जाने वाले किसी भी प्रोफाइल को अपरिवर्तनीय रूप से ब्लॉक कर दिया
                  जाएगा। वही भाग्य उनका इंतजार करता है जो एक से अधिक व्यक्तिगत
                  क्षेत्र बनाने का प्रयास करते हैं। कुछ मामलों में सभी उत्पादों
                  को ब्लॉक कर दिया जाएगा।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  प्रोफ़ाइल बनाने के लिए, मानक इलेक्ट्रॉनिक फ़ॉर्म का उपयोग
                  करें। उपयोगकर्ताओं को ऐप में या मूल संसाधन पृष्ठ पर “साइन अप”
                  बटन ढूंढना चाहिए और इलेक्ट्रॉनिक फॉर्म भरना चाहिए।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  Baji999 के लिए आपको चाहिए:
                </p>

                <ul className="list-item text-[#fff]">
                  <li className="text-sm list-disc md:text-lg">
                    एक उपयोगकर्ता नाम लिखें;
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    एक मजबूत पासवर्ड के बारे में सोचें;
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    अपनी पसंदीदा मुद्रा चुनें (बीडीटी, आईएनआर या पीकेआर);
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    अगर Refer Code है तो उसे जरूर इस्तेमाल करे ;
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    फिर, पंजीकरण के दूसरे चरण में जाने पर, आपको पूरा नाम, ई-मेल
                    और फोन नंबर जैसे डेटा भरने की जरूरत है;
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    आपको अपनी आयु की पुष्टि करने की आवश्यकता है और आप अपना
                    पंजीकरण पूरा कर सकते हैं।
                  </li>
                </ul>
                <button className="flex items-center justify-center mx-auto bg-gradient-to-r from-[#f69501] to-[#fbc004] rounded-[5px] text-lg h-[45px] px-[24px] text-white mt-3">
                  अभी पंजीकरण करें
                </button>
                <img
                  className="my-3 w-[60%] mx-auto"
                  src={register}
                  alt="register"
                />

                <h2 className="mb-3 text-2xl text-center text-white">
                  Baji999 लॉगिन
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  अपने गेमिंग प्लेटफ़ॉर्म खाते में लॉग इन करने से आप उन सभी गेम
                  और सामग्री पर नज़र रख सकते हैं, जिन तक आपकी पहुँच है। यह आपके
                  पुरस्कारों को देखना, अतिरिक्त सामग्री खरीदना और आपकी प्रोफ़ाइल
                  जानकारी में परिवर्तन करना भी आसान बनाता है।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  अपने व्यक्तिगत खाते में लॉग इन करने के लिए, वेबसाइट पर किसी भी
                  पृष्ठ के शीर्ष दाएं कोने पर स्थित ‘लॉगिन’ लिंक पर क्लिक करें।
                  फिर आपको अपना उपयोगकर्ता नाम और पासवर्ड दर्ज करने के लिए कहा
                  जाएगा। इस जानकारी को दर्ज करने के बाद, अपने गेमिंग खाते में
                  उपलब्ध सभी सुविधाओं तक पहुंच प्राप्त करने के लिए ‘लॉगिन’ पर
                  क्लिक करें।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  यदि आपको लॉग इन करने में कठिनाई हो रही है या साइट के किसी भी
                  पहलू में सहायता की आवश्यकता है, तो कृपया आगे की सहायता के लिए
                  हमारी ग्राहक सेवा टीम से संपर्क करें। हम यहां यह सुनिश्चित
                  करने के लिए हैं कि आपके पास सबसे अच्छा गेमिंग अनुभव हो!
                </p>

                <h2 className="mt-5 mb-3 text-2xl text-center text-white">
                  खाता सत्यापन
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  खाता सत्यापन गेमिंग प्लेटफॉर्म का एक महत्वपूर्ण हिस्सा है। यह
                  खिलाड़ियों की सुरक्षा सुनिश्चित करने में मदद करता है, साथ ही
                  सभी लेनदेन की वैधता की गारंटी देता है। उपयोगकर्ता खातों की
                  पुष्टि करके, Baji999 यह सुनिश्चित करता है कि कोई भी व्यक्ति जो
                  इसके प्लेटफॉर्म पर खेलता है, कानूनी उम्र का है और सहमत नियमों
                  और शर्तों के अनुसार कार्य कर सकता है।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  खाता सत्यापन में व्यक्तिगत पहचान दस्तावेज जैसे पासपोर्ट,
                  ड्राइविंग लाइसेंस आदि प्रदान करना शामिल है।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  सत्यापन प्रक्रिया को उपयोगकर्ताओं को धनराशि स्थानांतरित करते
                  समय धोखाधड़ी या चोरी से बचाने के लिए भी डिज़ाइन किया गया है।
                  खाते की पुष्टि करके, Baji999 पुष्टि कर सकता है कि उपयोगकर्ता
                  वास्तव में वह है जो वे कहते हैं कि वे हैं, और यह कि उनके कार्ड
                  विवरण और बैंकिंग जानकारी किसी भी तरह से चोरी या समझौता नहीं की
                  गई है। यह सुनिश्चित करता है कि सभी धन हस्तांतरण घुसपैठियों से
                  सुरक्षित और सुरक्षित हैं।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  इसके अलावा, सत्यापन प्रक्रिया खिलाड़ियों को प्लेटफॉर्म पर
                  खेलों में भाग लेने के लिए अलग-अलग नामों से कई खाते बनाने या
                  झूठे नामों का उपयोग करने से रोककर उन्हें जवाबदेह बनाए रखने में
                  भी मदद करती है। Baji999 हमेशा अपने उपयोगकर्ताओं की सुरक्षा के
                  लिए प्रतिबद्ध है और अपने प्लेटफॉर्म पर खेलते समय उनकी सुरक्षा
                  और सुरक्षा सुनिश्चित करने के लिए सभी आवश्यक उपाय करता है।
                </p>
              </div>

              <div className="border-b-[5px] border-[#353535] pb-4 mt-4 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  गेमिंग प्लेटफॉर्म Baji99 के फायदे और नुकसान
                </h2>
                <div className="md:flex">
                  <table className="md:w-[50%] md:m-3">
                    <thead className="">
                      <tr>
                        <th className="border-[2px] text-center border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          लाभ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          उदार बोनस पदोन्नति
                        </td>
                      </tr>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          खेलों की प्रभावशाली रेंज
                        </td>
                      </tr>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          स्लॉट मशीनों पर रिटर्न का उच्च प्रतिशत
                        </td>
                      </tr>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          सुरक्षा का उत्कृष्ट स्तर
                        </td>
                      </tr>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          लाइसेंस
                        </td>
                      </tr>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          प्रसंस्करण समय 15-30 मिनट से
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="md:w-[50%] mt-3 md:m-3 h-[90px]">
                    <thead className="">
                      <tr>
                        <th className="border-[2px] text-center border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          नुकसान
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          सीमित भुगतान विकल्प
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="border-b-[5px] border-[#353535] py-5 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  ऑनलाइन कैसीनो रेंज
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  ऑनलाइन कैसीनो में सर्वश्रेष्ठ जुआ मशीनों का चयन है। सभी
                  सॉफ्टवेयर की आपूर्ति 20 से अधिक प्रदाताओं द्वारा की जाती है।
                  इलेक्ट्रॉनिक कैटलॉग में स्लॉट और स्लॉट मशीन सावधानीपूर्वक चुने
                  गए हैं और स्थिर सॉफ़्टवेयर को लाइसेंस देते हैं। इसे बहकाया,
                  हैक या छेड़छाड़ नहीं किया जा सकता है। जीत के मामले में भुगतान
                  केवल भुगतान प्रतिशत से प्रभावित होता है। एक स्थिर यादृच्छिक
                  संख्या जनरेटर द्वारा प्रदान किए गए वर्चुअल सिमुलेटर की अखंडता।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  ऑनलाइन साइट की सूची में मिल सकते हैं:
                </p>
                <div className="flex flex-wrap mx-3">
                  <div className="flex-grow-1 w-[50%] !p-4">
                    <img
                      className="mb-2 w-[50%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={slots}
                      alt="स्लॉट्स"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      स्लॉट्स
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] !p-4">
                    <img
                      className="mb-2 w-[50%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={tableGames}
                      alt="टेबल के खेल"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      टेबल के खेल
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] !p-4">
                    <img
                      className="mb-2 w-[50%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={jackpots}
                      alt="जैकपॉट"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      जैकपॉट
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] !p-4">
                    <img
                      className="mb-2 w-[50%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={fishing}
                      alt="मछली पकड़ने"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      मछली पकड़ने
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] !p-4">
                    <img
                      className="mb-2 w-[20%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={liveDealer}
                      alt="लाइव डीलर्स"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      लाइव डीलर्स
                    </p>
                  </div>
                </div>

                <div className="grid-cols-2 md:grid">
                  <div className="md:p-3">
                    <h2 className="mb-3 text-center md:!text-left text-2xl text-white">
                      स्लॉट्स
                    </h2>
                    <p className="mb-0 text-sm text-white md:text-lg">
                      Baji999 पर स्लॉट कैसीनो में सबसे अधिक खेला जाने वाला और
                      सबसे लोकप्रिय खंड है। वे रोमांचक और पुरस्कृत गेमिंग
                      अनुभवों की एक बेजोड़ श्रृंखला पेश करते हैं। स्लॉट्स के
                      विशाल चयन के साथ, क्लासिक 3-रील से लेकर आधुनिक 5-रील
                      वीडियो स्लॉट तक, हर किसी को अपने लिए कुछ न कुछ मिल जाएगा।
                      सभी गेम अत्याधुनिक ग्राफिक्स, सहज एनिमेशन और ध्वनि प्रभाव
                      के साथ-साथ रोमांचक बोनस राउंड, मुफ्त स्पिन, जोकर और तितर
                      बितर प्रतीकों को वास्तव में आपके गेमिंग अनुभव को मसाला
                      देने का दावा करते हैं। खेल खेलें जैसे:
                    </p>
                  </div>
                  <div className="md:p-3">
                    <h2 className="mb-3 mt-4 md:!mt-0 text-center md:!text-left  text-2xl text-white">
                      टेबल के खेल
                    </h2>
                    <p className="mb-0 text-sm text-white md:text-lg">
                      Baji999 कैसीनो में टेबल गेम खिलाड़ियों को एक अनूठा और
                      रोमांचक अनुभव प्रदान करते हैं। क्लासिक से लेकर आधुनिक तक
                      सभी स्वादों और श्रेणियों के अनुरूप चुनने के लिए 89 गेम
                      हैं। चाहे आप तीन पत्ती का रोमांच पसंद करें, पोकर की जटिलता
                      या कुछ और, आपकी शैली के अनुरूप एक खेल होना निश्चित है।
                    </p>
                    <p className="mb-2 text-sm text-white md:text-lg">
                      Baji999 का माहौल किसी से पीछे नहीं है। कोई फर्क नहीं पड़ता
                      कि आप कौन सा खेल खेलते हैं, आप एक सुरक्षित वातावरण में
                      उच्च गुणवत्ता वाले मनोरंजन पर भरोसा कर सकते हैं।
                    </p>
                  </div>
                </div>

                <ul className="pl-3 list-item">
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    Bonus Mania;
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    WorldMatch 50s PinUp;
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    Arowanas Luck;
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    Asgardian Stones;
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    Big Win.
                  </li>
                </ul>

                <h2 className="mb-3 text-center md:!text-left  text-2xl text-white mt-4">
                  Baji live 999
                </h2>
                <p className="mb-0 text-sm text-white md:text-lg">
                  Baji999 कैसीनो में लाइव गेमिंग एक रोमांचक और शानदार गेमिंग
                  अनुभव का अनुभव करने का एक सही तरीका है। लाइव गेम में, खिलाड़ी
                  अन्य खिलाड़ियों के खिलाफ प्रतिस्पर्धा करते हुए वास्तविक समय
                  में वास्तविक डीलर या क्रुपियर के साथ बातचीत कर सकते हैं।
                  Baji999 पर उपलब्ध लाइव गेम्स में शामिल हैं:
                </p>

                <ul className="pl-3 my-3 list-item">
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    रूले
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    डांडा
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    पोकर
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    बैकारेट
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    अंदर बहार।
                  </li>
                </ul>
                <p className="mb-0 text-sm text-white md:text-lg">
                  ये सभी खेल बड़े जैकपॉट और पुरस्कार जीतने की बड़ी संभावना
                  प्रदान करते हैं, इसलिए खिलाड़ियों के पास जीतने के बहुत सारे
                  अवसर होते हैं! खिलाड़ी हमारे लाइव गेमिंग टेबल पर खेलकर विशेष
                  बोनस और प्रमोशन का भी आनंद ले सकते हैं – अपनी जीत बढ़ाने का एक
                  और तरीका!
                </p>
              </div>

              <div className="border-b-[5px] border-[#353535] !py-5 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  Bajii999 स्पोर्ट्स बेटिंग
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  Baji999 बांग्लादेश के सबसे अच्छे बेटिंग हाउसों में से एक है।
                  यह सभी प्रमुख लीगों और टूर्नामेंटों पर विभिन्न प्रकार के खेल
                  सट्टेबाजी के विकल्प और प्रतिस्पर्धी ऑड्स प्रदान करता है।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  साइट का यूजर इंटरफेस नेविगेट करने में आसान है, जिससे
                  उपयोगकर्ता जल्दी से वह ढूंढ सकते हैं जो वे ढूंढ रहे हैं।
                  Baji999 फुटबॉल, क्रिकेट, बास्केटबॉल, टेनिस, वॉलीबॉल, हॉकी,
                  रग्बी सहित, दांव लगाने के लिए खेलों की एक विस्तृत श्रृंखला
                  प्रदान करता है, आपको साइबरस्पोर्ट इवेंट्स पर मैच भी मिलेंगे।
                  यह इन-प्ले बेटिंग, प्री-मैच बेटिंग और वायदा बाजार भी प्रदान
                  करता है।
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  Baji999 का उद्देश्य आपको सूचित सट्टेबाजी के निर्णय लेने के लिए
                  आवश्यक सभी उपकरण प्रदान करने के लिए खेल सट्टेबाजी में नवीनतम
                  रुझानों को बनाए रखना है। आधुनिक प्लेटफ़ॉर्म एक सहज इंटरफ़ेस
                  प्रदान करता है जो उपयोग में आसान है, और बहुत सारी जानकारी और
                  आँकड़े प्रदान करता है ताकि आप सूचित विकल्प बना सकें।
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-[url('@components/images/baji999/bg1.webp')] bg-no-repeat bg-cover">
          <div className="content-container p-[15px]">
            <h2 className="text-2xl text-center text-white">बाजीस ऑप</h2>
            <div className="md:grid grid-cols-2 items-center mt-3 md:!mt-0">
              <div>
                <p className="text-sm text-white md:text-lg">
                  Baji999 ऐप किसी अन्य मानक ऐप की तरह ही आपके एंड्रॉइड
                  स्मार्टफोन या टैबलेट पर इंस्टॉल किया गया है। एक बार डाउनलोड
                  पूरा हो जाने के बाद, गेमर्स को कई तरह के लाभ दिए जाते हैं।
                  मुख्य खाते में असीमित पहुंच और कहीं भी दांव लगाने की क्षमता
                  है। सॉफ्टवेयर के सभी फायदों की सराहना करने के लिए, खिलाड़ियों
                  को 6 सरल चरणों का पालन करने की आवश्यकता है:
                </p>

                <ul className="list-item text-[#fff] mt-3">
                  <li className="text-sm list-disc md:text-lg">
                    आधिकारिक वेबसाइट पर जाएं।
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    मुख्य मेनू के नीचे “एंड्रॉइड के लिए डाउनलोड करें” पर क्लिक
                    करें।
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    निर्देशों का पालन करते हुए ऐप इंस्टॉल करें।
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    फ़ाइल लॉन्च करें और उपयोगकर्ता समझौते से सहमत हों।
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    व्यक्तिगत कैबिनेट या रजिस्टर में अधिकृत करें।
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    खाता जमा करें।
                  </li>
                </ul>
                <button className="flex items-center justify-center mx-auto bg-gradient-to-r from-[#f69501] to-[#fbc004] rounded-[5px] text-sm md:text-lg h-[45px] px-[24px] text-white mt-3">
                  ऐप डाउनलोड करें
                </button>
              </div>

              <div className="flex flex-col items-center mt-3">
                <img src={app} alt="app" className="w-[150px] md:w-[300px]" />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-[#353535]">
          <div className="content-container p-[15px] bg-[#484848] !mb-14">
            <div className="border-y-[5px] border-[#353535] !py-6 mt-4 md:mx-3">
              <h2 className="mb-3 text-2xl text-center text-white">
                साइट Baji999 का मोबाइल संस्करण
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                स्मार्टफोन संस्करणों की कार्यक्षमता किसी भी तरह से उनकी मुख्य
                साइट या मोबाइल ऐप से कमतर नहीं है। डेस्कटॉप संस्करण से मुख्य
                अंतर केवल इंटरफ़ेस के दृश्य डिज़ाइन में है। मेनू के अनुभागों को
                स्मार्टफोन और टैबलेट पर काम करने के लिए अनुकूलित किया गया है,
                इसलिए वे कम रिज़ॉल्यूशन वाली स्क्रीन पर भी काम करते हैं।
              </p>
              <p className="mb-4 text-sm text-white md:text-lg">
                दृश्य अंतर यह है कि खेलों के मुख्य खंड और मेनू व्यवस्थित किए
                जाते हैं ताकि उनका उपयोग करना आसान हो। साथ ही, ट्रैफिक बचाने के
                लिए, Baji999 वेबसाइट का मोबाइल संस्करण कम विज्ञापन पोस्टरों का
                उपयोग करता है, जो लोडिंग गति में स्पष्ट लाभ देता है। ये सभी उपाय
                यातायात की खपत को काफी कम करने की अनुमति देते हैं और यह
                सुनिश्चित करते हैं कि साइट तेज है।
              </p>
              <p className="mb-4 text-sm text-white md:text-lg">
                उपयोगकर्ता कार्यक्षमता में किसी भी तरह से वंचित नहीं है। सभी गेम
                और स्पोर्ट्स बेटिंग लॉन्च किए गए हैं और बिना किसी बाधा के काम
                करते हैं। आधुनिक तकनीक कम स्क्रीन रिज़ॉल्यूशन पर भी उच्च
                गुणवत्ता वाले चित्र प्रदर्शित करना संभव बनाती है। कभी-कभी
                ग्राफिक्स नेत्रहीन डेस्कटॉप संस्करण से भी बेहतर दिखते हैं। यह उन
                लोगों के लिए सबसे अच्छा विकल्प है जिनके पास डेस्कटॉप कंप्यूटर
                नहीं है या जो मोबाइल ऐप इंस्टॉल नहीं करना चाहते हैं।
              </p>
            </div>

            <div className="border-b-[5px] border-[#353535] !py-6 mt-4 md:mx-3">
              <h2 className="mb-3 text-2xl text-center text-white">
                जमा करने के तरीके
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                जब आपके बांग्लादेश Baji999 खाते में धनराशि जमा करने की बात आती
                है, तो आपके पास चुनने के लिए कई विकल्प होते हैं। सौभाग्य से,
                बांग्लादेश में खिलाड़ियों के लिए कई सुरक्षित जमा विधियां उपलब्ध
                हैं। इसमे शामिल है:
              </p>

              <ul className="list-item text-[#fff] my-3">
                <li className="text-sm list-disc md:text-lg">
                  bKash, Rocket, Nagad
                </li>
                <li className="text-sm list-disc md:text-lg">
                  स्थानीय बैंक हस्तांतरण। खिलाड़ी इस विधि का उपयोग सीधे अपने
                  स्थानीय बैंक खातों से धनराशि स्थानांतरित करने के लिए कर सकते
                  हैं। यह बांग्लादेश में खिलाड़ियों द्वारा उपयोग की जाने वाली
                  सबसे लोकप्रिय भुगतान विधियों में से एक है।
                </li>
                <li className="text-sm list-disc md:text-lg">
                  क्रिप्टोक्यूरेंसी। खिलाड़ी अपने खातों में धनराशि जमा करने के
                  लिए टीथर जैसी क्रिप्टोकरेंसी का भी उपयोग कर सकते हैं।
                </li>
              </ul>
              <p className="mb-4 text-sm text-white md:text-lg">
                ये बांग्लादेश Baji999 में खिलाड़ियों के लिए उपलब्ध कई जमा
                विधियों में से कुछ हैं। प्लेटफ़ॉर्म पर सभी लेन-देन सुरक्षित और
                एन्क्रिप्टेड हैं, इसलिए आप सुनिश्चित हो सकते हैं कि आपके फंड
                सुरक्षित हैं। बड़ी संख्या में उपलब्ध निकासी विकल्पों के कारण
                अपनी जीत को वापस लेना भी आसान है। तो तैयार हो जाइए खेलने और मज़े
                करने के लिए!
              </p>
            </div>

            <div className="border-b-[5px] border-[#353535] !py-6 mt-4 md:mx-3">
              <h2 className="mb-3 text-2xl text-center text-white">निकासी</h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                बांग्लादेश बाजी.999 में निकासी के निम्नलिखित तरीके उपलब्ध हैं:
                बीकैश, नागद, रॉकेट और लोकल बैंक ट्रांसफर। अपने खाते से पैसे
                निकालने के लिए, इन चरणों का पालन करें:
              </p>

              <ul className="list-item text-[#fff] mt-3">
                <li className="text-sm list-decimal md:text-lg">
                  Visit the main website;मुख्य वेबसाइट पर जाएँ;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  Log in to your account;अपने अकाउंट में लॉग इन करें;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  Go to the “Account” section;“खाता” अनुभाग पर जाएं;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  Select “Withdrawal” from the menu;मेनू से “निकासी” चुनें;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  उपलब्ध विकल्पों की सूची से भुगतान की अपनी पसंदीदा विधि का चयन
                  करें;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  Enter the amount you wish to withdraw and click “Next”;वह राशि
                  दर्ज करें जिसे आप निकालना चाहते हैं और “अगला” पर क्लिक करें;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  स्क्रीन पर अतिरिक्त निर्देशों का पालन करें (उदाहरण के लिए एक
                  सुरक्षा कोड दर्ज करें) और पुष्टि करें कि आपके द्वारा दर्ज किए
                  गए सभी
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  विवरण आपके अनुरोध को सबमिट करने से पहले सही हैं;आपके निकासी
                  अनुरोध पर कार्रवाई किए जाने पर आपको सूचित किया जाएगा।
                </li>
              </ul>
              <p className="mb-4 text-sm text-white md:text-lg">
                कृपया ध्यान दें कि कुछ भुगतान विधियों को संसाधित होने में अधिक
                समय लग सकता है; हालाँकि, आप अपने Baji999 खाते के “खाता” अनुभाग
                में निकासी की स्थिति की जाँच कर सकते हैं। पैसे निकालने के लिए,
                आपको पहले अपने पहचान दस्तावेज़ भेजकर, खाता पहचान प्रक्रिया से
                गुज़रना होगा।
              </p>
            </div>

            <div className="border-b-[5px] border-[#353535] !py-6 mt-4 md:mx-3">
              <h2 className="mb-3 text-2xl text-center text-white">
                गेमिंग प्लेटफॉर्म Baji999 की सुरक्षा
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                बाजी लाइव 999 एक बांग्लादेशी सट्टेबाज है जो विभिन्न खेल
                सट्टेबाजी और जुआ बाजारों में प्रतिस्पर्धी बाधाओं की पेशकश करता
                है। अपने ग्राहकों के डेटा और लेन-देन की सुरक्षा सुनिश्चित करने
                के लिए, Baji.999 अपने सर्वर और उपयोगकर्ता के कंप्यूटर के बीच
                प्रसारित जानकारी को एन्क्रिप्ट करने के लिए SSL संस्करण 3 विधि का
                उपयोग करता है। यह सुरक्षित एन्क्रिप्शन तकनीक व्यक्तिगत जानकारी
                जैसे क्रेडिट कार्ड नंबर, पासवर्ड, खाता विवरण आदि को साइबर
                अपराधियों द्वारा इंटरसेप्ट किए जाने से बचाने में मदद करती है।
              </p>
              <p className="mb-4 text-sm text-white md:text-lg">
                इसके अलावा, खेल के मैदान के पास कुराकाओ सरकार से वैध लाइसेंस है।
                इसका मतलब है कि Baji999 कानून के दायरे में काम करता है और पूरी
                तरह से कानूनी ऑनलाइन कैसीनो और बेटिंग साइट है।
              </p>
            </div>

            <div className="border-b-[5px] border-[#353535] !py-6 mt-4 md:mx-3">
              <h2 className="mb-3 text-2xl text-center text-white">
                तकनीकी ग्राहक सेवा
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                बाजी 999 लाइव ग्राहकों को उच्चतम गुणवत्ता वाली तकनीकी सेवा
                प्रदान करता है। गेमिंग के दौरान आपके सामने आने वाली किसी भी
                तकनीकी समस्या में आपकी मदद करने के लिए समर्पित पेशेवरों की एक
                टीम 24/7 उपलब्ध है।
              </p>
              <p className="mb-4 text-sm text-white md:text-lg">
                टीम ऑनलाइन गेमिंग तकनीक के सभी पहलुओं से अच्छी तरह वाकिफ है,
                इसलिए वे आपकी समस्या को जल्दी और सही तरीके से हल करने में मदद कर
                सकते हैं। यदि आपको लॉग इन करने में समस्या आ रही है या आपको अपना
                खाता सेट करने में सहायता चाहिए, तो समस्या का समाधान होने तक
                सपोर्ट आपको प्रत्येक प्रक्रिया के बारे में चरण दर चरण बता सकता
                है। आप लाइव चैट, ईमेल के माध्यम से संपर्क में रह सकते हैं या आप
                फेसबुक या व्हाट्सएप जैसे सोशल मीडिया पर लिख सकते हैं।
              </p>
              <p className="mb-4 text-sm text-white md:text-lg">
                Baji999 में, ग्राहक सेवा गेमप्ले का एक महत्वपूर्ण हिस्सा है और
                वे इसे बहुत गंभीरता से लेते हैं। टीम आपके किसी भी प्रश्न या
                समस्या में आपकी मदद करने के लिए है। यदि आपको कभी कोई समस्या आती
                है, तो हमसे संपर्क करने में संकोच न करें – हमें मदद करने में
                खुशी होगी! अक्सर पूछे जाने वाले प्रश्नों की एक विस्तृत सूची भी
                है, जहाँ आप अपना समय बचाने के लिए अपने प्रश्नों के उत्तर पा सकते
                हैं।
              </p>
            </div>

            <div className="!py-6 mt-4 md:mx-3">
              <h2 className="mb-3 text-2xl text-white">
                अक्सर पूछे जाने वाले प्रश्नों
              </h2>
              <h2 className="mb-3 text-2xl text-white">
                मैं Bajil Ive 999 कैसीनो में कैसे शामिल हो सकता हूँ?
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                Baji999 कैसीनो में शामिल होना बहुत आसान है! आपको केवल अपना
                व्यक्तिगत विवरण दर्ज करके और एक उपयोगकर्ता नाम और पासवर्ड चुनकर
                एक खाता बनाना है। जैसे ही आपका खाता बन जाता है, आप तुरंत खेलना
                शुरू कर सकते हैं। अतिरिक्त प्ले मनी के लिए आप हमारे सुरक्षित
                भुगतान समाधानों में से एक का उपयोग करके अपने खाते में जमा भी कर
                सकते हैं।
              </p>
              <h2 className="mb-3 text-2xl text-white">
                क्या Baji999 कैसीनो में मेरा लेन-देन सुरक्षित है?
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                बिल्कुल! हम यह सुनिश्चित करने के लिए नवीनतम एन्क्रिप्शन तकनीक का
                उपयोग करते हैं कि आपके सभी लेनदेन सुरक्षित और सुरक्षित हैं। आपकी
                व्यक्तिगत और वित्तीय जानकारी को हमेशा निजी रखा जाता है और इसे
                कभी भी किसी के साथ साझा नहीं किया जाता है।
              </p>
              <h2 className="mb-3 text-2xl text-white">
                क्या कोई बोनस ऑफ़र उपलब्ध हैं?
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                हाँ, आप सही जगह पर आए हैं! Baji999 नए और मौजूदा खिलाड़ियों के
                लिए समान रूप से अविश्वसनीय बोनस और प्रचार प्रदान करता है। बोनस
                नियमित रूप से अपडेट किए जाते हैं, इसलिए नवीनतम ऑफ़र के लिए साइट
                पर नज़र रखें। आप हमारे सभी प्रचारों और विशेष ऑफ़र पर नियमित
                अपडेट प्राप्त करने के लिए न्यूज़लेटर के लिए साइन अप भी कर सकते
                हैं।
              </p>
              <h2 className="mb-3 text-2xl text-white">
                Baji999 कैसीनो द्वारा स्वीकार किए जाने वाले भुगतान के तरीके क्या
                हैं?
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                Baji999 विभिन्न तरीकों से भुगतान स्वीकार करता है, जिसमें स्थानीय
                बैंक हस्तांतरण, ई-वॉलेट और क्रिप्टोकरेंसी शामिल हैं। स्वीकृत
                भुगतान विधियों के बारे में अधिक जानने के लिए, मुख्य वेबसाइट पर
                “भुगतान विधियाँ” खोजें।
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withLocation(Baji999);
